import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import { getCurrentAdmin } from "../../redux/actions/currentAdmin.action";
import AuthenticatedUserInformationService from "../../services/authenticatedUserInformation.service";
import style from "../NavigationVRauthAdmin/NavigationVRauthAdmin.module.scss";
import authinticationService from "../../services/Authentication.service";
import Avatar from "../uiComponents/Avatar/Avatar";
import "./NavigationVRauthAdmin.css";
import { useState } from "react";
import BurgerCloseSVGSpec from "../../svg/BurgerCloseSVGSpec/BurgerCloseSVGSpec";
import BurgerSVGSpec from "../../svg/BurgerSVGSpec/BurgerSVGSpec";

function NavigationVRauthAdmin() {
  let accessToken = AuthenticatedUserInformationService.getUserToken();

  let specialistId = AuthenticatedUserInformationService.getUserId();

  function logout() {
    authinticationService.logout();
  }
  const dispatch = useDispatch();

  const { currentAdmin } = useSelector((state) => state.currentAdmin);
  useEffect(() => {
    dispatch(getCurrentAdmin());
  }, []);

  const [isOpen, setIsOpen] = useState(false);
  const openMenu = () => {
    setIsOpen(!isOpen);
    if (isOpen === true) {
      document.body.style.overflow = "auto";
    } else {
      document.body.style.overflow = "hidden";
    }
  };

  const closeMenu = () => {
    setIsOpen(false);
    document.body.style.overflow = "auto";
  };

  return (
    <div className={style.navigation}>
      <div className={style.nav__wrapper}>
        <div className={style.logo}>
          <NavLink
            to="/dashboard/new-specialists-accounts"
            activeClassName="navigationActiveLink"
            onClick={closeMenu}
          >
            <img src="/img/logoWithoutBackground.png" alt="logo" />
          </NavLink>
        </div>
        <div className={style.link}>
          <NavLink to="/all-admins" activeClassName="navigationActiveLink">
            Admins
          </NavLink>
          <NavLink to="/all-specialists" activeClassName="navigationActiveLink">
            Specialists
          </NavLink>
          <NavLink to="/all-students" activeClassName="navigationActiveLink">
            Students
          </NavLink>
          <NavLink to="/admin-payments" activeClassName="navigationActiveLink">
            Payments
          </NavLink>
          <NavLink to="/coupons" activeClassName="navigationActiveLink">
            Coupons
          </NavLink>
          <NavLink to="/admin-groups" activeClassName="navigationActiveLink">
            Groups
          </NavLink>
          <NavLink to="/props" activeClassName="navigationActiveLink">
            Props
          </NavLink>
          <NavLink to="/dashboard" activeClassName="navigationActiveLink">
            Dashboard
          </NavLink>
          <NavLink to="/vr" activeClassName="navigationActiveLink">
            VR device
          </NavLink>
          <Link className={style.log__out__link} onClick={logout}>
            Log Out
          </Link>
          <Link to="/admin-personal-info">
            <Avatar
              className={style.avatar}
              photoUrl={currentAdmin?.photoUrl}
            />
          </Link>
        </div>
      </div>
      <div className={style.burger__item} onClick={openMenu}>
        {isOpen ? <BurgerCloseSVGSpec /> : <BurgerSVGSpec />}
      </div>

      {/* mobile */}
      <div
        className={style.burger__menu}
        style={isOpen ? { dispalay: "flex" } : { display: "none" }}
      >
        <NavLink
          to="/all-admins"
          activeClassName="navigationActiveLink"
          onClick={openMenu}
        >
          Admins
        </NavLink>
        <NavLink
          to="/all-specialists"
          activeClassName="navigationActiveLink"
          onClick={openMenu}
        >
          Specialists
        </NavLink>
        <NavLink
          to="/all-students"
          activeClassName="navigationActiveLink"
          onClick={openMenu}
        >
          Students
        </NavLink>
        <NavLink
          to="/admin-payments"
          activeClassName="navigationActiveLink"
          onClick={openMenu}
        >
          Payments
        </NavLink>
        <NavLink
          to="/admin-personal-info"
          activeClassName="navigationActiveLink"
          onClick={openMenu}
        >
          Personal Info
        </NavLink>
        <NavLink
          to="/coupons"
          activeClassName="navigationActiveLink"
          onClick={openMenu}
        >
          Coupons
        </NavLink>
        <NavLink
          to="/admin-groups"
          activeClassName="navigationActiveLink"
          onClick={openMenu}
        >
          Groups
        </NavLink>
        <NavLink
          to="/props"
          activeClassName="navigationActiveLink"
          onClick={openMenu}
        >
          Props
        </NavLink>
        <NavLink
          to="/dashboard"
          activeClassName="navigationActiveLink"
          onClick={openMenu}
        >
          Dashboard
        </NavLink>
        <NavLink
          to="/vr"
          activeClassName="navigationActiveLink"
          onClick={openMenu}
        >
          VR device
        </NavLink>
        <Link className={style.log__out__link} onClick={logout}>
          Log Out
        </Link>
      </div>
    </div>
  );
}

export default NavigationVRauthAdmin;
